$('.animated-input label').click(function() {
    $(this).addClass('active');
    $(this).find('input').focus();
});

//Inputs
$('.animated-input input').on('focusin', function() {
    $(this).parent().find('label').addClass('active');
});
$('.animated-input input').on('focusout', function() {
    if (!this.value) {
        $(this).parent().find('label').removeClass('active');
    }
});
$('.animated-input input').filter(function() {
    if (this.value != "") {
        $(this).parent().find('label').addClass('active');
    }
});

//TextAreas
$('.animated-input textarea').on('focusin', function() {
    $(this).parent().find('label').addClass('active');
});
$('.animated-input textarea').on('focusout', function() {
    if (!this.value) {
        $(this).parent().find('label').removeClass('active');
    }
});
$('.animated-input textarea').filter(function() {
    if (this.value != "") {
        $(this).parent().find('label').addClass('active');
    }
});
