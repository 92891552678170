/**
 * First we will load all of this project's JavaScript dependencies
 */

require('./bootstrap');

/**
 * APP
 */
require('jquery/dist/jquery');
require('./complements/animated-input');
require('./recaptcha');
require('./main');
